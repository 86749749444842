html,
body {
    height: 100%;
}

.page-wrap {
    min-height: 87.3%;
    margin-bottom: -86px;
    @media (max-width: $screen-xs) {
        margin-bottom: -106px;
    }
}

.page-wrap:after {
    content: "";
    display: block;
}

.page-footer,
.page-wrap:after {
    /* .push must be the same height as footer */
    height: 86px;
    @media (max-width: $screen-xs) {
        height: 106px;
    }
}
