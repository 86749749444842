.widget-tools {
  $max-height: 313px;

  position: relative;
  left: -80px;
  max-height: $max-height;
  z-index: 30;
  width: 60px;

  & .btn-icon {
    padding: 10px 3px;
    & .fa {
      font-size: 30px;
    }
    & div {
      font-size: 10px;
    }
  }
}

.word-break {
  word-wrap: break-word;
}

.widget-zoom {
  position: absolute;
  top: 37%;
  left: 5px;
  z-index: 100;

  & .btn-icon {
    padding: 10px 3px;
    & .fa {
      font-size: 30px;
    }
    & div {
      font-size: 10px;
    }
  }
}
