.support{
  padding: 15px 0;

  & h3{
    color: $header-hor-menu-bg-color;
    font-weight: 800;
    font-size: 15px;
    line-height: 55px;
    border-bottom: 1px solid $gray-lighter;
  }

  & form {
    padding: 15px 0;
  }
}