.editable {
  .span-color {
    width: 18px;
    height: 18px;
    display: block;
    &-name{
      float: left;
      padding-left: 22px;
    }
  }
  .editable-view {
    display: block;
    position: relative;
  }

  & .editable-edit {
    display: none;
  }

  & .edit .editable-edit {
    display: block;
  }

  & .edit .editable-view {
    position: relative;
    display: none;
  }
}