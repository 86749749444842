$color-blue: #428ccd;
.sub-header {
  margin: 0 0 15px;
  border-bottom: 1px solid #eeeeee;
  h4 {
    margin-top: 0;
    font-size: 14px;
    font-weight: 300;
  }
}

.upload-box-details {
  background: #fff;
}

.upload-box,
.upload-video-box {
  background: #fff;
  padding: 27px 20px 0;
  display: grid;
  .tab-content & {
    padding: 0;
  }
  &-tab {
    padding: 0 0 20px 0;
  }
  &-control {
    margin-bottom: 15px;
  }
  .upload-container {
    border: 3px dashed #e1f2fe;
    text-align: center;
    padding: 37px;
    margin-bottom: 25px;
    .upload-info {
      color: $color-blue;
      h3 {
        font-size: 21px;
        font-weight: bold;
        margin: 0 0 8px;
      }
      button {
        background: $color-blue;
        color: #fff;
        height: 34px;
        padding: 0 60px;
        font-size: 13px;
        font-weight: 600;
        border: none;
        &:hover {
          background: lighten($color-blue, 10%);
          transition: background 0.2s ease;
        }
      }
    }
  }
  .form-inline {
    margin-bottom: 28px;
    .form-control {
      min-width: 260px;
      margin-right: 15px;
      @media (max-width: $screen-xs) {
        min-width: 100%;
      }
    }
  }
  .upload-files,
  .upload-videos {
    ul {
      padding: 0;
    }
    li,
    div {
      .upload-file-img {
        width: 100%;
        height: 130px;
        background: center center no-repeat;
        background-size: cover;
      }
      .remove,
      .cloud,
      .refresh,
      .remove-video {
        position: absolute;
        font-size: 20px;
        line-height: 14px;
        color: #fff;
        text-shadow: 0px 0px 7px #000;
        top: 6px;
        cursor: pointer;
        opacity: 1;
        &-active {
          color: #3f74a3;
          opacity: 1;
          &:hover {
            color: #3f74a3;
          }
        }
      }
      .remove,
      .cloud,
      .refresh,
      .remove-video {
        &:hover {
          color: #3f74a3;
        }
      }
      .remove,
      .remove-video {
        right: 6px;
      }
      .cloud {
        left: 6px;
      }
      .refresh {
        left: 10px;
        top: 10px;
        z-index: 50;
      }
      &:hover {
        transition: border 0.2s ease;
        .remove,
        .cloud,
        .refresh,
        .remove-video {
          transition: opacity, color 0.2s ease;
          opacity: 1;
        }
      }
    }
  }
  &-details {
    position: relative;
    &-file-img {
      margin-right: 20px;
      margin-bottom: 15px;
      width: 133px;
      height: 120px;
      background: center center no-repeat;
      background-size: cover;
    }
    & .portlet-title {
      border: 0;
      margin: 0;
      padding: 0;
    }
    & i.view {
      opacity: 1;
      display: block;
      font-size: 50px;
      z-index: 0;
      position: absolute;
      left: 45px;
      top: 0;
      &:before {
        text-align: center;
        display: block;
        line-height: 115px;
      }
    }
    &:hover {
      & i {
        opacity: .5;
      }
    }
  }
}

.modal .modal-body > img.img-responsive {
  margin: auto;
  height: 85%;
  width: auto;
}

.upload-files,
.upload-videos {
  ul {
    padding: 0;
  }
  li,
  div {
    .upload-file-img {
      width: 100%;
      height: 130px;
      background: center center no-repeat;
      background-size: cover;
    }
    .remove,
    .cloud,
    .refresh,
    .remove-video {
      position: absolute;
      font-size: 20px;
      line-height: 14px;
      color: #fff;
      text-shadow: 0px 0px 7px #000;
      top: 6px;
      cursor: pointer;
      opacity: 1;
      &-active {
        color: #3f74a3;
        opacity: 1;
        &:hover {
          color: #3f74a3;
        }
      }
      &.disalbed {
        color: #fff;

      }
    }
    .remove,
    .cloud,
    .refresh,
    .remove-video {
      &:hover {
        color: #3f74a3;
      }
    }
    .remove,
    .remove-video {
      right: 6px;
    }
    .cloud {
      left: 6px;
    }
    .refresh {
      left: 10px;
      top: 10px;
      z-index: 50;
    }
    &:hover {
      transition: border 0.2s ease;
      .remove,
      .cloud,
      .refresh,
      .remove-video {
        transition: opacity, color 0.2s ease;
        opacity: 1;
      }
    }
  }
}

.dz-button{
  padding: 0px !important;
  border-radius: 4px;
}
