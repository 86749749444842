/* You can add global styles to this file, and also import other style files */
@media screen and (max-width: 480px) {
    .page-header .page-header-menu {
        padding: 0px;
    }

    .page-header .page-header-menu .hor-menu {
        margin: 0 -10px !important;
    }

    .page-head .page-title {
        padding-top: 0px;
    }

    .page-title button {
        width: 100%;
        margin-top: 15px;
    }

    .breadcrumb {
        text-align: center;
    }

    .my-dashboard-list-item.__header {
        display: none;
    }

    .page-content .container {
        padding: 0px;
    }

    .col-md-8.col-sm-8.__title {
        width: 75%;
        float: left;
    }

    #item .col-md-8.col-sm-8.__title {
        padding: 0px;
    }

    #item .col-md-8.col-sm-8.__title p {
        margin: 0px;
        margin-top: 7px;
        padding-top: 7px;
        border-top: 1px solid #cecece;
    }

    #item .col-md-1.col-sm-1.__status p {
        margin: 0px;
        margin-top: 7px;
        padding-top: 8px;
    }

    .col-md-1.col-sm-1.__status {
        width: 25%;
        float: right;
    }

    .col-md-3.col-sm-3.__date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px;
    }

    .col-md-3.col-sm-3.__date a {
        margin: 0 2vh;
    }

    .panel-heading {
        padding: 0px 15px !important;
    }

    .my-dashboard-list-item .__title,
    .my-dashboard-list-item .__status,
    .my-dashboard-list-item .__date {
        margin-bottom: 0px !important;
    }

    .col-md-1.col-sm-1.__status p {
        margin-bottom: 0px;
    }

    .col-md-3.col-sm-3.__date {
        padding-top: 15px;
        padding-bottom: 8px;
    }

    .page-content {
        padding-top: 10px !important;
    }

    .page-header .page-header-menu {
        background: #fff;
    }

    .profile-content .portlet.light {
        padding: 0px;
    }

    .list-separated {
        margin: 0px !important;
        padding: 0px !important;
    }

    .page-title h1 {
        white-space: pre-line;
        text-align: center;
        padding: 15px 15px 0px 15px;
    }

    .page-container .page-head .page-title {
        padding-bottom: 0px !important;
    }

    .support {
        padding: 0px 15px !important;
    }

    .page-header .page-header-top .container {
        flex-direction: column;
    }

    .page-header .page-header-top .top-menu {
        margin: 20px auto !important;
    }

    .page-header .page-header-top .page- {
        margin-top: 17px;
    }

    .profile-userpic {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        float: none;
        margin: 2vw 0 0 0 !important;
    }

    .profile-userpic img {
        margin: 0px !important;
        width: auto !important;
    }

    .profile-usermenu {
        padding-bottom: 0px !important;
    }

    .profile-sidebar .portlet.light {
        padding: 0px !important;
    }

    .profile-userpic .profile-avatar:before {
        margin: 0px !important;
    }

    .tiles {
        margin: 0px !important;
    }

    input.form-control.form-control-solid {
        border-bottom: 2px solid;
        border-top: 2px solid;
    }

    .profile-content .col-md-9 {
        padding: 0 21px !important;
    }

    .btn-group-vertical {
        bottom: 0 !important;
        left: 1px !important;
        width: 100% !important;
        top: auto !important;
        display: flex !important;
    }

    #nameMonth {
        width: auto !important;
    }

    .btn-group-vertical > .btn:first-child:not(:last-child) {
        border-radius: inherit !important;
    }

    .time-line-content {
        padding-bottom: 58px !important;
    }

    .btn-group-vertical > .btn + .btn,
    .btn-group-vertical > .btn + .btn-group,
    .btn-group-vertical > .btn-group + .btn,
    .btn-group-vertical > .btn-group + .btn-group {
        margin-top: 0 !important;
    }

    .widget-tools .btn-icon .fa {
        font-size: 30px;
        color: #333333;
    }

    .modal-dialog[_ngcontent-c3] {
        width: 100% !important;
        margin: auto !important;
    }

    .modal:before {
        height: auto !important;
    }

    .panel-body {
        padding: 7px;
    }

    .modal .modal-header .close {
        left: auto;
        right: 30px;
        top: 0px;
    }

    button.close.close1.fullscrean {
        right: 11vw !important;
    }

    .widget-zoom {
        top: 17px !important;
        left: auto !important;
        right: 12px;
    }

    .filters {
        position: fixed;
        top: 0px;
        z-index: 1000;
    }

    .close-filters {
        margin: 13px;
        float: right;
    }

    .collaps-panel {
        z-index: 10000;
    }



    .col-xs-12.inf.tbm.info-view {
        text-align: center;
    }

    .inf-team {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tit-team {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 35px !important;
    }

    .bor {
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: #e7ecf1;
    }

    .col-xs-12.col-sm-5.head-1 {
        text-align: center;
    }

    .col-xs-12.tit-views.tbm.title-view {
        background-color: #13558c;
        color: #fff;
    }

    .row.nom.responsive-table-by-me {
        padding: 0 5px;
    }

    .col-xs-12.tit-views.tbm.title-set {
        white-space: pre-wrap;
    }

    .info-share .form-control {
        width: 100% !important;
        float: left;
    }

    .inf-file {
        border-left: 2px solid;
        border-color: #fafbfc;
    }

    .row.row-files {
        padding-top: 10px;
    }

    .modal-dialog.modal-lg {
        margin: 0px !important;
        width: 100% !important;
    }

    .col-xs-7.col-sm-12.inf-file.tbm.info-thumb {
        padding: 5px 0px;
    }

    .info-share .input-group-btn {
        width: 100% !important;
        float: right;
        margin-top: 7px;
    }

    .info-link .form-control.password {
        width: 100% !important;
        float: right;
    }

    .info-link .input-group-btn {
        width: 100% !important;
        float: right;
        margin-top: 7px;
        margin-bottom: 2.5px;
    }

    @media screen and (max-width: 422px) {
        .col-xs-12.tit-views.tbm.title-link {
            min-height: 49px;
        }
    }

    .col-xs-12.inf.tbm.info-link {
        min-height: 130px;
    }

    .info-frame .form-control {
        width: 100% !important;
        float: left;
    }

    .info-frame .input-group-btn {
        width: 100% !important;
        float: right;
        margin-top: 7px;
    }

    .inf {
        min-height: 90px !important;
    }

    button.btn.blue {
        border-radius: 0px !important;
    }
    .col-xs-12.tit-views.nop.tbm.title-he {
        background-color: #13558c;
        color: #fff;
    }
    .col-xs-12.col-sm-12.inf-file.tbm.info-desc {
        border: none !important;
    }
    .collaps-panel.in {
        width: 100% !important;
    }
    .collaps-panel .comments-panel{
        width: 100% !important;
    }
    @media screen and (max-width: 480px) {
        .collaps-panel .comments-panel{
            z-index: 0 !important;
        }
    }
    #event-edit-modal .modal-footer{
        margin-bottom: 62px;
    }
    @media screen and (min-width: 460px) and (max-width: 480px) {
        .page-title button {
            margin-top: 0px;
        }

        .profile-content .portlet.light {
            padding: 15px;
        }

        form.ng-untouched.ng-pristine.ng-valid {
            margin: auto !important;
        }
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .col-xs-12.inf.tbm.info-link {
        height: 127px;
        display: flex;
        align-items: center;
    }
}

.img img {
    max-width: 150px;
    width: auto;
    height: auto;
    margin: 11px;
}

.navbar-default .navbar-nav > li > a {
    color: #044E89 !important;
    font-weight: 700;
}

.ticket-header-label {
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.navbar {
    margin-bottom: 0px !important;
}
.widthfull {
    width: 100%;
}
@media screen and (max-width: 768px) {
    .tit-team-main {
        display: none !important;
    }
    .tit-file-main {
        display: none !important;
    }
}
@media screen and (min-width: 768px) {

    .row.row-files{
        padding-bottom: 5px !important;
        padding-top: 5px !important;
    }
    .tit-file{
        display: none !important;
    }
    .tit-file-main {
        padding: 0px !important;
        text-align: center;
        margin: 11px 0px;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        display: block !important;
    }
    .tit-team {
        display: none !important;
    }
    .row.row-ma {
        padding-bottom: 10px;
    }

    .tit-team-main {
        text-align: center;
        min-height: 0px;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 0;
        display: block !important;
    }

    .filters {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
    }

    .bigright {
        float: right !important;
    }

    .close-filters {
        margin: 13px;
        float: right;
    }

}

@media screen and (min-width: 762px) and (max-width: 991px) {
    .col-xs-12.tit-views.tbm.title-date {
        background-color: #13558c;
        color: #fff;
    }

    .col-xs-7.inf-team.tbm.info-but {
        font-size: 10px;
    }

    .col-xs-7.inf-team.tbm.info-role {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .col-xs-7.col-sm-12.inf-file.tbm.info-file-act {
        padding: 0px !important;
    }

    .col-xs-7.col-sm-12.inf-file.tbm.info-file-act button {
        margin: 0px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .col-xs-7.col-sm-12.inf-file.tbm.info-file-act {
        padding: 0px !important;
    }

    .col-xs-7.col-sm-12.inf-file.tbm.info-file-act button {
        margin: 0px !important;
    }
}

@media screen and (min-width: 776px) {
    .container-fluid {
        /*padding: 0px !important;*/
    }
    .col-xs-12.col-sm-12.inf-file.tbm.info-desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

@media screen and (max-width: 761px) {
    .col-xs-5.col-sm-12.tit-file.tbm.title-file-act {
        display: none;
    }

    .col-xs-12.col-sm-12.inf-file.tbm.info-file-act button {
        margin: 0 20px;
    }

    .row.row-files {
        padding-top: 10px;
    }

    .col-xs-12.col-sm-12.inf-file.tbm.info-desc {
        padding: 0px;
        line-height: 1.6;
    }

    .col-xs-12.col-sm-7.head-2 {
        justify-content: center !important;
    }

    .col-xs-3.col-sm-1.head-3 {
        margin: 0px !important;
    }

    .col-xs-9.head-2 {
        margin: 0px !important;
    }

    .tit-team {
        padding: 0px 0 !important;
    }

    .inf-team {
        padding: 3px 0 !important;
        min-height: 35px !important;
    }

    .row.row-ma {
        padding-bottom: 5vw;
        border-bottom: 3px solid;
        margin-bottom: 5vw;
    }

    .row.row-se {
        padding-bottom: 5vw;
        border-bottom: 3px solid;
        margin-bottom: 5vw;
    }
}

/* You can add global styles to this file, and also import other style files */
@media screen and (min-width: 481px) and (max-width: 992px) {
    .col-xs-12.inf.tbm.info-view {
        text-align: center;
    }

    .col-xs-12.col-sm-5.head-1 {
        text-align: center;
    }
    .col-xs-12.tit-views.nop.tbm.title-he {
        background-color: #13558c;
        color: #fff;
    }
    .col-xs-12.tit-views.tbm.title-view {
        background-color: #13558c;
        color: #fff;
    }

    .col-xs-9.col-sm-6.head-2 {
        text-align: center;
    }

    .bor {
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-right: 1px solid;
        border-left: 1px solid;
        border-color: #e7ecf1;
    }

    .filters {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
    }

    .close-filters {
        margin: 13px;
        float: right;
    }

    .page-header .page-header-menu {
        padding: 0px;
    }

    .page-header .page-header-menu .hor-menu {
        margin: 0 -10px !important;
    }

    .page-head .page-title {
        padding-top: 0px;
    }

    .page-title button {
        width: 100%;
    }

    .breadcrumb {
        text-align: center;
    }

    .my-dashboard-list-item.__header {
        display: none;
    }

    .page-content .container {
        padding: 0px;
    }

    .col-md-8.col-sm-8.__title {
        width: 75%;
        float: left;
    }

    #item .col-md-8.col-sm-8.__title {
        padding: 0px;
    }

    #item .col-md-8.col-sm-8.__title p {
        margin: 0px;
        margin-top: 7px;
        padding-top: 7px;
        border-top: 1px solid #cecece;
    }

    #item .col-md-1.col-sm-1.__status p {
        margin: 0px;
        margin-top: 7px;
        padding-top: 8px;
    }

    .col-md-1.col-sm-1.__status {
        width: 25%;
        float: right;
    }

    .col-md-3.col-sm-3.__date {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px;
    }

    .col-md-3.col-sm-3.__date a {
        margin: 0 2vh;
    }

    .panel-heading {
        padding: 0px 15px !important;
    }

    .my-dashboard-list-item .__title,
    .my-dashboard-list-item .__status,
    .my-dashboard-list-item .__date {
        margin-bottom: 0px !important;
    }

    .col-md-1.col-sm-1.__status p {
        margin-bottom: 0px;
    }

    .col-md-3.col-sm-3.__date {
        padding-top: 15px;
        padding-bottom: 8px;
    }

    .page-content {
        padding-top: 10px !important;
    }

    .page-header .page-header-menu {
        background: #fff;
    }

    .profile-content .portlet.light {
        padding: 0px;
    }

    .list-separated {
        margin: 0px !important;
        padding: 0px !important;
    }

    .page-title h1 {
        white-space: pre-line;
        text-align: center;
        padding: 15px 15px 0px 15px;
    }

    .page-container .page-head .page-title {
        padding-bottom: 0px !important;
    }

    .support {
        padding: 0px 15px !important;
    }

    .page-header .page-header-top .container {
        flex-direction: column;
    }

    .page-header .page-header-top .top-menu {
        margin: 20px auto !important;
    }

    .page-header .page-header-top .page- {
        margin-top: 17px;
    }

    .profile-userpic {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        float: none;
        margin: 2vw 0 0 0 !important;
    }

    .profile-userpic img {
        margin: 0px !important;
        width: auto !important;
    }

    .profile-usermenu {
        padding-bottom: 0px !important;
    }

    .profile-sidebar .portlet.light {
        padding: 0px !important;
    }

    .profile-userpic .profile-avatar:before {
        margin: 0px !important;
    }

    .tiles {
        margin: 0px !important;
    }

    input.form-control.form-control-solid {
        border-bottom: 2px solid;
        border-top: 2px solid;
    }

    .profile-content .col-md-9 {
        padding: 0 21px !important;
    }
}

.nom {
    margin: 0px !important;
}

.nop {
    padding: 0px !important;
}
.nopRole {
    padding: 0px !important;
    height: 50px;
}
.row.nom.responsive-table-by-me {
    padding: 0 15px;
    white-space: nowrap;
    margin-top: 10px !important;
}

@media screen and (min-width: 992px) {
    .prise-modal .panel-body {
        min-height: 417px;
    }
    .bor {
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-right: 1px solid;
        border-color: #e7ecf1;
    }

    .bor.nop.col-he {
        width: 4%;
        text-align: center;
        border-left: 1px solid #e7ecf1;
    }

    .col-md-2.bor.nop.col-view {
        width: 13%;
    }

    .col-md-1.bor.nop.col-date {
        width: 6.7%;
    }

    .col-md-2.bor.nop.col-share {
        width: 11.4%;
    }

    .col-md-1.bor.nop.col-share {
        width: 5.7%;
    }

    .col-md-2.bor.nop.col-link {
        width: 18.2%;
    }

    .col-md-1.bor.nop.col-set {
        width: 20.3%;
    }

    .col-md-2.bor.nop.col-frame {
        width: 15.7%;
    }

    .col-md-1.bor.nop.col-remove {
        width: 5.7%;
    }
}

.tit-views {
    padding: 4px;
    line-height: 1.42857143;
    border-bottom: 1px solid;
    border-color: #e7ecf1;
    background-color: #f1f4f7;
    text-align: center;
}

.col-xs-12.inf.tbm.info-he {
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.col-xs-12.tit-views.tbm.title-he {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.col-xs-12.inf.tbm.info-date {
    text-align: center;
}

.col-xs-12.inf.tbm.info-share {
    text-align: center;
}

.col-xs-12.inf.tbm.info-link {
    text-align: center;
}

.col-xs-12.inf.tbm.info-set {
    text-align: center;
}

.col-xs-12.inf.tbm.info-frame {
    text-align: center;
}

.col-xs-12.inf.tbm.info-remove {
    text-align: center;
    padding: 0px;
}

.inf {
    background-color: #fbfcfd;
    min-height: 59px;
    padding-top: 17px !important;
}

.inf:hover {
    background-color: #EEF1F5;
}

.info-set .input-group.input-group-sm {
    margin-top: -17px;
}

.tit-team {
    text-align: center;
    min-height: 0px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
}

.inf-team {
    text-align: center;
    padding: 15px 0;
    min-height: 51px;
}

.row-ma .inf-team {
    background-color: #8ed2f9;
    color: #000;
}

.row-se .inf-team {
    background-color: #e0ebf9;
}

.ovner-ver {
    font-size: 13px !important;
    padding: 2px 5px 2px 5px;
    background-color: #269c00;
    color: #fff;
    border-radius: 3px;
    font-weight: 300;
}

.col-xs-7.inf-team.tbm.info-but {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-xs-5.tit-team.tbm.title-but {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.col-xs-5.inf-team.tbm.info-but {
    color: #ccc;
}

.col-xs-7.inf-team.tbm.info-first {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-xs-7.inf-team.tbm.info-last {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-xs-7.inf-team.tbm.info-email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.row-ma span.label.label-success {
    border: 1px solid;
}

.row-se span.role {
    background: #2685bb;
    padding: 2px 8px;
    border: 1px solid;
    border-radius: 6px;
    border-color: #000;
    color: #fff;
}

.col-xs-12.col-sm-5.head-1 {
    padding: 0px;
}

.vis-panel.vis-center {
    border: none !important;
}

.col-xs-12.col-sm-7.head-2 {
    margin-top: 20px;
    margin-bottom: 10px;
    min-height: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.row.row-files {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    border-color: #f4f6f8;
}

.modal-dialog.modal-md {
    width: 93%;
}

.tit-file {
    padding: 0px !important;
    text-align: center;
    margin: 11px 0px;
    font-size: 14px;
    font-weight: 600;
    color: #969696;
}

.inf-file {
    text-align: center;
    min-height: 48px;
    line-height: 46px;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-thumb img {
    height: 48px !important;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-file-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-file-act button {
    margin: 5px;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-atta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.row-files .inf-file:hover {
    background-color: #f7f7f7;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-thumb:hover {
    background-color: transparent !important;
}

.col-xs-7.col-sm-12.inf-file.tbm.info-file-act {
    background-color: transparent !important;
}

.trial-line-navigation-line-cursor.ui-draggable.ui-draggable-handle {
    margin: 0px !important;
    border: none !important;
}

.atta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (min-width: 992px){
    .mob .tit-views {
        display: none;
    }
}
@media screen and (max-width: 991px){
    .not-mob .tit-views {
        display: none;
    }
}
.thumbnail img {
    max-height: 200px !important;
    max-width: 200px !important;
}
#filter-dropdown{
  top:50px;
  background-color: #f8f8f8;
  /* border: 1px solid #f8f8f8; */
  border: 1px solid #231f2542;
  }
td .btn {
  padding: 6px 3px;
}
.show-midal-element{
  text-align: center;
  opacity: 1;
}
.show-midal-element-sub{
  left: 30%;
  position: relative !important;
}
.filter-by:hover {
  cursor: pointer;
}
.mb-1 {
  margin-bottom: 10px;
}
.tooltip-inner img {
  max-width: 300px;
}
.tooltip .tooltip-inner {
  max-width: 315px;
}
.tooltip.in {
  opacity: 1;
}