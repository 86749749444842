input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table.table-vertical-align-center > tbody > tr > td {
  vertical-align: middle;
}

.container-full {
  margin: 0 auto;
  width: 100%;
}

.list-events{
  & .btn-delete, & .btn-edit, & .btn-warning, & .btn-danger{
    font-size: 10px;
    font-weight: 800;
  }
}
.list-events-left{
  .btn{
    margin: 0 10px 10px 0;
  }
  .btn:last-child{
    margin: 0 0 10px 0;
  }
  @media (max-width: $screen-sm-max) {
    .btn{
      display: block;
      margin: 0 0 10px;
    }
  }
}
.list-events-right{
  text-align: right;

  @media (max-width: $screen-sm-max) {
    .btn{
      display: block;
      margin: 0 0 10px;
    }
  }
  @media (max-width: $screen-xs-max) {
    .btn{
      display: block;
      margin: 0 0 10px;
    }
  }
}

.list-events-action{
  text-align: right;
  .btn {
    margin-left: 5px;
  }

  @media (max-width: $screen-md-max) {
    .btn{
      display: block;
      margin: 0 0 5px;
    }
  }
}

.event-form {
  & .date-end, & .date-start-label, & .time_start {
    display: none;
  }
  & .btn-optional {
    display: block;
  }

  & .optional {
    & .date-end, & .date-start-label {
      display: block;
    }
    & .time_start {
      display: inline;
    }
  }

  & .form-inline .form-group label {
    color: $text-muted;
  }

  & .tab-content {
    padding: 15px 15px;

    &-left-col{
      padding-left: 0;
    }
  }
}
