.breadcrumb .fa,
.page-head .container.without-margin {
    margin: 0;
}

.time-line-content {
    background-color: #fff;
    & #visualization {
        margin: 0;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-shadow: 0px 0px 4px #FFF;
    }
}

.actions-filter {
    .group-btn-left {
        margin-left: 250px;
    }
    .dropdown-menu .mt-checkbox {
        margin: 15px;
        color: $gray;
        font-weight: 300;
        white-space: nowrap;
        font-family: $font-family-sans-serif;
    }
    .menu-dropdown {
        position: absolute;
        padding-left: 170px;
    }
    #filter-dropdown {
        overflow-y: scroll;
        min-width: 250px;
        .close-filters {
            position: absolute;
            right: 5px;
            z-index: 999;
            font-size: 20px;
            line-height: 20px;
            a {
                color: #666;
                margin: 0;
                padding: 14px;
                position: relative;
                i {
                    -webkit-transition: 0.4s ease;
                    -moz-transition: 0.4s ease;
                    transition: 0.4s ease;
                }
                &:hover {
                    background-color: #fff;
                    color: #666;
                    i {
                        -webkit-transform: rotateZ(720deg);
                        -moz-transform: rotateZ(720deg);
                        transform: rotateZ(720deg);
                    }
                    // &:after {
                    //     content: "close filters";
                    //     position: absolute;
                    //     top: -4px;
                    //     left: -12px;
                    //     font-size: 12px;
                    //     width: 70px;
                    //     display: block;
                    //     font-weight: 400;
                    // }
                }
            }
        }
        .divider {
            margin: 0;
        }
    }
    .logo {
        height: 30px;
        margin: 7px 14px;
    }
    a {
        &.back-link{
            display: inline-block;
            padding: 15px;
            //float: left;
        }
        &.logo-fixed{
            display: inline-block;
            padding: 5px 0;
            float: left;
        }
        display: block;
        text-decoration: none;
        padding: 16px;
        margin: 0 5px;
        font-weight: 600;
        color: $header-hor-menu-bg-color;
        &:hover {
            color: #fff;
            background-color: $header-hor-menu-bg-color;
        }
    }
}

.trial-line-ctrl {
    top: 5px;
    left: 5px;
    background-color: #fff;
    margin: 0;
    border-radius: 3px;
    padding: 0;
    min-height: 45px;
    max-height: 50px;
}

.monthBlock {
    width: 400px;
}
.modal-content .pull-right.tabs-button{
    border-top: 1px solid #90b3d2 !important;
}
.card .nav-tabs {
  position:relative;
  top: -1px
}
#nameMonth {
    position: absolute;
    top: 0;
    //left: 46%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 15px;
    margin-top: 20px;
    font-size: 19px;
    font-weight: 800;
    border-radius: 7px;
    width: fit-content;
    margin-left: 80px;

    @media screen and (max-height: 600px) {
        padding: 5px;
    }
    @media screen and (max-width: 1650px) {
      top: 30px;
    }
    & .month,
    & .trialName {
        text-align: center;
        margin: 2px 0;
    }

    & .trialName {
        font-size: 12px;
    }

    & .trialName {
        @media screen and (max-height: 600px) {
            font-size: 10px;
        }
    }

    & .month {
        @media screen and (max-height: 600px) {
            font-size: 16px;
        }
    }
}

#nameMonth:after,
#nameMonth:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

#nameMonth:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0);
    border-width: 10px;
    margin-left: -10px;
}

#nameMonth:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: rgba(255, 255, 255, 0.6);
    border-width: 16px;
    margin-left: -16px;
}

//@media screen and (max-width: 1200px) {
//  #nameMonth {
//    margin-top: 52px;
//    right: 0;
//    &:after,
//    &:before{
//      display: none;
//    }
//  }
//}
