.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  @media only screen and (max-width: 992px){
    display: none;
  }
  
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;

  & .modal-title {
    font-weight: 600;
  }
}
// #preview-modal .zoom_deactivate {
//   width: 630px;
// }

// .modal.fade .modal-dialog {
//  transform: translate(0, -2%) !important;
// }
.modal-preview.zoom_deactivate{
  width: 500px !important;
  height: 400px;
  .modal-content{
    // width: 90% !important;
    // height:100% !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 5%;
    .modal-header{
      width: 100%;
    }
    .modal-body{
      width: 100%;
      text-align: center;
      .download-icon{
        width: 120px;
        height: 130px;
          margin: 10px auto;
          @media only screen and (max-width: 768px){
            height: 90px;
          }
      }
      .download-text{
        margin: 30px 0px;
        font-size: 20px;
        font-weight: 700;
        color: #000;
        text-align: center;
        @media only screen and (max-width: 768px){
          font-size: 15px;
        }
      }
      a{
        color: #fff;
      }
    }
    
  }
}
