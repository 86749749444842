.vis-panel.vis-left.trackSecond, .vis-panel.vis-left.trackSecond .vis-content, .vis-panel.vis-left.trackSecond .vis-content .vis-labelset{
  height: 100%;
}
.vis-panel.vis-left.trackSecond .vis-content .vis-labelset .vis-label {
  height: 45% !important;
}
.track_view .vis-panel.vis-bottom {
  background-color: rgba(196, 196, 196, 0.5)
}
.vis-panel.vis-left.trackSecond .vis-content .vis-labelset .vis-label {
  border-bottom: none;
}
.vis-panel.vis-left.trackSecond .vis-content{
  top: 29px !important;
  height: 100%;
}
.vis-panel.vis-left.trackSecond .vis-content .vis-itemset{
  height: 100%;
}
.vis-panel.vis-center.trackSecond .vis-group{
  height: 45% !important;
}
.vis-panel.vis-center.trackSecond .vis-group{
  border-bottom: none;
}
.vis-panel.vis-center.trackSecond .vis-group .vis-item{
  top: 10px !important;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item{
  top: 75px !important;
}
.vis-panel.vis-center.trackSecond .vis-content {
  top: 29px !important;
  height: 100%;
}
.vis-panel.vis-center.trackSecond .vis-content .vis-itemset{
  height: 100% !important;
}
.trial-line-navigation.trackSecond, .vis-panel.vis-bottom.trackSecond {
  top: 44% !important;
}
.vis-panel.vis-center.trackSecond .vis-item.vis-line {
  border-left-style: none;
}
.vis-panel.vis-center.trackSecond .vis-item {
  border: none;
}
.vis-panel.vis-center.trackSecond {
  border-top-style: none;
  border-bottom-style: none
}
.vis-panel.vis-left.trackSecond{
  border-top-style: none;
  border-bottom-style: none
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .ticket,
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .ticket,
.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .ticket {
  border-radius: 0 20px 20px 0;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .ticket:before{
  top: -29px;
  border: 14px solid;
  border-color: transparent  transparent #bbb #bbb;
  left: 0;
  right: unset;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .ticket:after{
  border-color: transparent transparent #fff #fff;
  left: 0;
  right: unset;
  top: -27px;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .ticket:before{
  bottom: -31px;
  border: 15px solid;
  border-color:#bbb transparent transparent #bbb;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .ticket:after{
  border-color: #fff transparent transparent #fff;
  left: 0;
  bottom: -28px;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .ticket:hover:after{
  border-color: transparent transparent #eeeeee #eeeeee;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .ticket:hover:after{
  border-color: #eeeeee transparent transparent #eeeeee;
}

.vis-panel.vis-center.trackSecond .vis-item-overflow{
  overflow: visible;
}
.vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
  border-left: 1px solid #bbb;
  height: 68%;
  top: -68%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -1px;
  width: 1px;
  text-align: center;
}

.vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
  border-right: 1px solid #bbb;
  height: 40%;
  position: absolute;
  top: 110%;
  margin-left: auto;
  margin-right: auto;
  left: -1px;
  width: 1px;
  text-align: center;
}

.vis-panel.vis-left.trackThird, .vis-panel.vis-left.trackThird .vis-content,
.vis-panel.vis-left.trackThird .vis-content .vis-labelset{
  height: 100%;
}
.vis-panel.vis-left.trackThird .vis-content .vis-labelset .vis-label {
  height: 35% !important;
}
.vis-panel.vis-left.trackThird .vis-content .vis-labelset .vis-label:first-child {
  height: 35% !important;
}
.vis-panel.vis-left .vis-content .vis-labelset .vis-label .vis-inner  {
  display: none;
}
.vis-panel.vis-left.trackThird .vis-content .vis-labelset .vis-label {
  border-bottom: none;
}
.vis-panel.vis-left.trackThird .vis-content{
  top: 29px !important;
  height: 100%;
}
.vis-panel.vis-left.trackThird .vis-content .vis-itemset{
  height: 100%;
}
.vis-panel.vis-center.trackThird .vis-group{
  height: 32% !important;

}
.vis-panel.vis-center.trackThird .vis-group{
  border-bottom: none;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(2){
  z-index: 11;
}
.vis-panel.vis-center.trackThird .vis-group .vis-item{
  top: 10px !important;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
  top: 85px !important;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .vis-item{
  top: 75px !important;
}
.vis-panel.vis-center.trackThird .vis-content {
  top: 0 !important;
  height: 100%;
}
.vis-panel.vis-center.trackThird .vis-content .vis-itemset{
  height: 100% !important;
}
.trial-line-navigation.trackThird, .vis-panel.vis-bottom.trackThird {
  top: 35% !important;
}
.vis-panel.vis-center.trackThird .vis-item.vis-line {
  border-left-style: none;
}
.vis-panel.vis-center.trackThird .vis-item {
  border: none;
}
.vis-panel.vis-center.trackThird {
  border-top-style: none;
  border-bottom-style: none;
  height: 100% !important
}
.vis-panel.vis-left.trackThird{
  border-top-style: none;
  border-bottom-style: none
}

.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .ticket:after,
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .ticket:after{
  border-color: transparent transparent #fff #fff;
  left: 0;
  right: unset;
  top: -27px;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(1) .ticket:before{
  bottom: -31px;
  border: 15px solid;
  border-color:#bbb transparent transparent #bbb;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .ticket:before,
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .ticket:before{
  top: -29px;
  border: 14px solid;
  border-color: transparent transparent #bbb #bbb;
  left: 0;
  right: unset;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(1) .ticket:after{
  border-color: #fff transparent transparent #fff;
  left: 0;
  bottom: -28px;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .ticket:hover:after,
.vis-content .vis-group:nth-child(3) .vis-item.vis-selected .ticket:after,
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .ticket:hover:after{
  border-color: transparent  transparent #eeeeee #eeeeee;
}
.vis-content .vis-group:nth-child(2) .vis-item.vis-selected .ticket:after {
  border-color: transparent  transparent #eeeeee #eeeeee;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(1) .ticket:hover:after,
.vis-content .vis-group:nth-child(1) .vis-item.vis-selected .ticket:after{
  border-color: #eeeeee transparent transparent #eeeeee;
}

.vis-panel.vis-center.trackThird .vis-item-overflow{
  overflow: visible;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline{
  border-left: 1px solid #bbb;
  height: 16%;
  top: -16%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  left: -1px;
  text-align: center;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
  border-left: 1px solid #bbb;
  height: 126%;
  top: -126%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
  left: -1px;
  text-align: center;
}
.view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
  height: 208px;
  top: -113%;
}
.view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
  height: 16%;
  top: -16%;
}
.vis-panel.vis-center .vis-group:nth-child(1) .vis-selected .vis-item-content .ticket .border_timeline{
  border-right: 1px solid #555;
}
.trackFirst.vis-panel.vis-center .vis-group:nth-child(1) .vis-selected .vis-item-content .ticket .border_timeline{
  border-left: 1px solid #555;
  border-right: none;
}
.vis-panel.vis-center.trackFirst .vis-group:first-child .vis-selected .ticket:after {
  border-color: #eeeeee transparent transparent #eeeeee;
}
.vis-panel.vis-center .vis-group:nth-child(2) .vis-selected .vis-item-content .ticket .border_timeline,
.vis-panel.vis-center .vis-group:nth-child(3) .vis-selected .vis-item-content .ticket .border_timeline{
  border-left: 1px solid #555;
}
.vis-panel.vis-center .vis-group:nth-child(1) .vis-selected .vis-item-content .ticket:before {
  border-color: #555 transparent transparent #555;
}
.vis-panel.vis-center .vis-group:nth-child(2) .vis-selected .vis-item-content .ticket:before,
.vis-panel.vis-center .vis-group:nth-child(3) .vis-selected .vis-item-content .ticket:before{
  border-color: transparent transparent #555 #555;
}
.vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
  border-right: 1px solid #bbb;
  height: 12%;
  position: absolute;
  top: 116%;
  margin-left: auto;
  margin-right: auto;
  left: -1px;
  text-align: center;
}

.vis-panel.vis-center.trackFirst .vis-group:first-child .ticket:after {
  border-color:#fff transparent transparent #fff;
  left: 0;
  bottom: -28px;
}
.vis-panel.vis-center.trackFirst .vis-group:first-child .ticket:before {
  bottom: -31px;
  border: 15px solid;
  border-color:#bbb transparent transparent #bbb;
}
.vis-panel.vis-center.trackFirst .vis-group:first-child .ticket:hover:after{
  border-color:  #eeeeee transparent  transparent  #eeeeee;
}

.vis-panel.vis-center.trackFirst .vis-item-overflow{
  overflow: visible;
}
.vis-panel.vis-center.trackFirst .vis-group:first-child .border_timeline{
  border-left: 1px solid #bbb;
  height: 999px;
  position: absolute;
  top: 100%;
  margin-left: auto;
  margin-right: auto;
  left: -1px;
  width: 1px;
  text-align: center;
}
.view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline {
  height: 56%;
  top: -56%;
}
.view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
  height: 60%;
  top: 115%;
}
.track_select_items {
  width: 332px
}
@media (min-width: 1280px) {
  .vis-panel.vis-center.trackThird .vis-group{
    height: 28% !important;
  }
//   .ticket{
//     height: 148px;
//   }
//   .ticket-header{
//     padding: 2px;
//   }
//   .img_lazy{
//     height: 85px;
//   }
//   .ticket-body-text .text{
//     height: 30px;
//   }
//   .ticket-body p {
//     -webkit-line-clamp: 2;
// }
}
@media (min-width: 1439px) {
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 25%;
    top: -25%;
  }
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 148%;
    top: -148%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 34px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 128px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 60%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 68%;
    top: -68%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 158%;
    top: -158%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 24%;
    top: 100%;
  }
  .track_select_items {
    width: 332px
  }
}
@media (min-width: 1518px) {
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 25%;
    top: -25%;
  }
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 148%;
    top: -148%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 34px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 128px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 60%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 68%;
    top: -68%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 158%;
    top: -158%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 24%;
    top: 100%;
  }
  .track_select_items {
    width: 332px
  }
}
@media (min-width: 1586px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 158%;
    top: -158%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 34px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 128px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 160px;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 68%;
    top: -68%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 158%;
    top: -158%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 64px;
    top: 100%;
  }
  .track_select_items {
    width: 332px
  }
  .vis-panel.vis-center.trackThird .vis-group{
    height: 32% !important;
  }
  .ticket{
    height: 190px;
  }
  .ticket-header{
    padding: 5px;
  }
  .img_lazy{
    height: 110px;
  }
  .ticket-body-text .text{
    height: 69px;
  }
  .ticket-body p {
      -webkit-line-clamp: 5;
  }
}

@media (min-width: 1919px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 154%;
    top: -154%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 23%;
    top: -23%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 59%;
    top: 115%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 35%;
    top: -35%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 99%;
    top: -99%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item {
    top: 143px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 129px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 55px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 63%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 69%;
    top: -69%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 160%;
    top: -160%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 26%;
    top: 110%;
  }
  .track_select_items {
    width: 332px
  }
}
@media (min-width: 2130px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 178%;
    top: -178%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 85%;
    top: 115%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 23%;
    top: -23%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 35%;
    top: -35%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 108%;
    top: -108%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item {
    top: 143px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 129px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 55px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 90%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 78%;
    top: -78%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 178%;
    top: -178%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 47%;
    top: 110%;
  }
  .track_select_items {
    width: 332px
  }
}

@media (min-width: 2395px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 70px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 198%;
    top: -198%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 117%;
    top: 115%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 35%;
    top: -35%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 112%;
    top: -112%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item {
    top: 143px !important;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 129px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 20%;
    top: -20%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 55px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 120px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 122%
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 85%;
    top: -85%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 198%;
    top: -198%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 73%;
    top: 110%;
  }
  .track_select_items {
    width: 328px;
  }
}

@media (min-width: 2559px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 100px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline{
    height: 61%;
    top: -61%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item {
    top: 182px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .vis-item {
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 278%;
    top: -278%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 109%;
    top: 115%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 51%;
    top: -51%
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 116%;
    top: -116%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline {
    height: 156%;
    top: -156%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 107px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 200px !important
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 114%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 279%;
    top: -279%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 92%;
    top: 92%;
  }
  .track_select_items {
    width: 326px;
  }
}
@media (min-width: 2875px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 100px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline{
    height: 61%;
    top: -61%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item {
    top: 182px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .vis-item {
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 305%;
    top: -305%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 51%;
    top: -51%
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 148%;
    top: 115%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 125%;
    top: -125%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 107px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 200px !important
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 153%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 167%;
    top: -167%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 306%;
    top: -306%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 108%;
    top: 107%;
  }
  .track_select_items {
    width: 323px;
  }
}
@media (min-width: 3071px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 100px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 200px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .vis-item {
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 158%;
    top: -158%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 51%;
    top: -51%
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 108%;
    top: 115%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 107px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 200px !important
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 272%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 68%;
    top: -68%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 358%;
    top: -358%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 377px;
    top: 83%;
  }
  .track_select_items {
    width: 323px;
  }
}
@media (min-width: 3835px) {
  .vis-panel.vis-center.trackThird .vis-group .vis-item{
    top: 100px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 52%;
    top: -52%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .vis-item{
    top: 200px !important;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .vis-item {
    top: 200px !important;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline {
    height: 384%;
    top: -384%;
  }
  .view_tracks .vis-panel.vis-center.trackThird .vis-group:nth-child(2) .border_timeline {
    height: 42%;
    top: -42%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline {
    height: 266%;
    top: 115%;
  }
  .view_tracks .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline {
    height: 165%;
    top: -157%;
  }
  .vis-panel.vis-center.trackSecond .vis-group .vis-item {
    top: 107px !important;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .vis-item {
    top: 200px !important
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(1) .border_timeline{
    height: 272%;
  }
  .vis-panel.vis-center.trackSecond .vis-group:nth-child(2) .border_timeline{
    height: 198%;
    top: -198%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(3) .border_timeline{
    height: 385%;
    top: -385%;
  }
  .vis-panel.vis-center.trackThird .vis-group:nth-child(1) .border_timeline{
    height: 193%;
    top: 115%;
  }
  .track_select_items {
    width: 311px
  }
}
.track_view .ticket{
  display: inline-flex;
  z-index: 1;
}
.track_view .ticket:hover{
  z-index: 3;
}
.track_view .vis-item-content {
  width: 100%;
  text-align: center;
}
.track_view .vis-item {
  min-width: 340px;
  border: none
}
.track_view .vis-item:hover {
  min-width: 355px;
}
.track_view .vis-item.vis-selected {
  box-shadow: none
}
.track_view .ticket {
  text-align: left;
  border: none;
}

.track_view .back_strip {
  background: #757575;
  border: 2px solid #bbb;
  bottom: 40%;
  z-index: 0;
  display: none;
}
.track_view .vis-panel.vis-center .vis-group .vis-item.vis-readonly.vis-line {
  display: none;
}
//.track_view .trial-line-navigation{
//  display: none;
//}
#assignModal select {
  font-family: "Open Sans", sans-serif;
}
#assignModal .modal-header:before, #assignModal .modal-header:after{
  display: table-caption;
}
#assignModal .modal-header .close {
  margin-top: -4px;
}
#assignModal .modal-footer{
  padding: 15px;
}
#assignModal .modal-body .form-group:last-child {
  margin-bottom: 0;
}
.track_view .trial-line-navigation-line-cursor {
  background-color: rgba(0, 0, 40, 0) !important;
}
.navigation_track.trackSecond{
  position: relative;
  top: 45%;
  width: 80%;
  margin: 0 auto;
}
.navigation_track.trackThird{
  position: relative;
  top: 36%;
  width: 80%;
  margin: 0 auto;
}
