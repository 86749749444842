.collaps-panel {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  right: -20px;
  margin-right: 20px;
  //white-space: nowrap;
  vertical-align: middle;
  //line-height: 30px;
  background-color: #fff;
  box-shadow: -2px 0 16px rgba(0, 0, 0, .4);
  -webkit-transition: width 1s ease;
  -moz-transition: width 1s ease;
  -o-transition: width 1s ease;
  transition: width 1s ease;

  & .active-comment{
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  &.in {
    width: 450px;

    & .active-comment{
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }

  & .comments-panel {
    position: fixed;
    top: 0;
    width: 450px;
    height: 100%;
    overflow-y: scroll;
    z-index: 3;
    background-color: #fff;

    &-box {
      padding-right: 10px;
      padding-left: 10px;

      & .comments-form{
        margin-left: 0;
        min-height: 65px;

        & .text-message {
          padding: 0;
          & textarea{
            width: 100%;
          }
        }

      }

      & li {
        padding-bottom: 10px;
        list-style-type: none;

        & .comment {
          font-weight: 600;
          font-size: 18px;
          border-bottom: 1px dotted #bbb;
          word-wrap: break-word;

          &-title {
            position: relative;
            height: 30px;
            border-bottom: 1px dotted #bbb;
            font-family: $font-family-sans-serif;

            & .owner {
              position: absolute;
              left: 0;
              font-size: 10px;
            }

            & .date {
              position: absolute;
              right: 0;
              font-size: 10px;
            }
          }

          &-msg {
            color: $gray;
            margin: 0;
            white-space: normal;
            overflow: hidden;
            //min-height: 35px;
            text-align: left;
            line-height: 20px;
            padding-bottom: 5px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.sidebar-notes {

  & p{

    margin: 0;
  }
}

.view-panel-box {
  padding-left: 10px;
  //margin-left: -20px;

  & .view {
    list-style-type: none;

    & .title {
      font-weight: 600;
      font-size: 18px;
      border-top: 1px dotted #bbb;
      border-bottom: 1px dotted #bbb;
      width: 100%;
      overflow-wrap: break-word;
      overflow: hidden;
      white-space: normal;
      line-height: 1.3;
      padding: 10px 10px 10px 25px;
      text-align: left;
      cursor: pointer;

      & .event-thumbnail{
        width: 70px;
        height: 55px;
        background-size: cover;
        float: left;
        margin-right: 10px;
      }
    }
    & .notes{
      overflow-wrap: break-word;
      width: 100%;
      display: block;
      margin: 0;
      padding: 10px 10px 10px 25px;

      white-space: normal;
      overflow: hidden;
      text-align: left;
      line-height: 1.4;
      border-bottom: 1px dotted #bbb;

      & p{
        margin: 0;
        padding-top: 0px;
      }
    }
    & .event-title {
      font-weight: 600;
      font-size: 15px;
      padding-left: 30px;
    }
  }
}