/***
Custom buttons based on bootstrap SASS
***/

.btn {
  outline: none !important;
  @if $theme-type == "default" {
    box-shadow: none !important;
  }

  &:hover {
    transition: all 0.3s;
  }
}

.btn:not(.btn-sm):not(.btn-lg) {
  line-height: 1.44;
}

/***
Custom color buttons 
***/

@each $name, $colors in $component-colors {
  .btn.#{"" + $name}:not(.btn-outline) {
    @include button-variant(map-get($colors, font), map-get($colors, base), map-get($colors, base));
  }

  .btn.btn-outline.#{"" + $name} {
    border-color: map-get($colors, base);
    color: map-get($colors, base);
    background: none;

    &:hover,
    &:active,
    &:active:hover,
    &:active:focus,
    &:focus,
    &.active {
      border-color: map-get($colors, base);
      color: map-get($colors, font);
      background-color: map-get($colors, base);
    }
  }

  .btn.#{"" + $name}-stripe {
    border-left: 4px solid map-get($colors, base) !important;
  }
}

/* Circle Buttons */

.btn-circle {
  border-radius: 25px !important;
  overflow: hidden;
}

.btn-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
  height: 34px;
  width: 34px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;

  > [class^="icon-"],
  > i {
    text-align: center;
    margin-top: 2px;
  }
}

.btn-group.btn-group-circle {
  > .btn {
    &:first-child {
      border-radius: 25px 0 0 25px !important;
    }

    &:last-child {
      border-radius: 0 25px 25px 0 !important;
    }
  }
}

.btn-group.btn-group-devided {
  > .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-group-vertical.btn-group-vertical-circle {
  > .btn {
    &:first-child {
      border-radius: 25px 25px 0 0 !important;
    }

    &:last-child {
      border-radius: 0 0 25px 25px !important;
    }
  }
}

@if $theme-type == "material-design" {
  $uppercase-padding: 8px 14px 7px 14px !default;
  $uppercase-padding-lg: 12px 26px 10px 26px !default;
  $uppercase-padding-sm: 6px 18px 6px 18px !default;
  $uppercase-padding-xs: 3px 8px 3px 8px !default;

  .btn:not(.md-skip) {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    @include border-radius($general-border-radius);
    overflow: hidden;
    position: relative;
    user-select: none;

    @extend .md-shadow-z-1;

    .btn-group > & {
      @extend .md-shadow-z-1;
    }

    .search-form &,
    .btn-theme-panel & {
      box-shadow: none !important;
      overflow: visible;
    }

    &.btn-link {
      box-shadow: none;
      color: $link-color;

      &:hover {
        text-decoration: none;

        @extend .md-shadow-z-1;
      }
    }

    &:hover {
      @extend .md-shadow-z-2-hover;
    }

    &:active {
      @extend .md-shadow-z-3-hover;
    }

    padding: $uppercase-padding;

    > i {
      margin-top: 0;
      margin-left: 3px;
      margin-right: 3px;
    }

    &.btn-sm {
      font-size: 11px;
      padding: $uppercase-padding-sm;
    }

    &.btn-xs {
      font-size: 11px;
      padding: $uppercase-padding-xs;
    }

    &.btn-lg {
      font-size: 16px;
      padding: $uppercase-padding-lg;
    }

    &.btn-icon-only {
      padding-left: 0;
      padding-right: 0;

      > i {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.__date {
  @media (max-width: $screen-md-max) {
    & .btn {
      margin-bottom: 5px;
    }
  }
}