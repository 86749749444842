.tab-comments {
  //overflow-y: auto;
  //overflow-x: hidden;
  //max-height: calc(100vh - 210px);
  //height: 900px;
  //  overflow-y: auto;
    //-webkit-overflow-scrolling: touch; /* lets it scroll lazy */
}

.tab-content>.active :not(.more-description){
  li{
    //list-style:none;
    list-style-position: inside;
  }
}

.comments {
  border-bottom: 1px dotted #bbb;
  padding: 20px 0 20px 0;

  &-box {
    @media only screen and (max-width: 992px){
      padding: 0px;
    }
    & li {
      padding-bottom: $padding-large-vertical;
      list-style-type: none;
      border: 0;
    }

    &-template {
      display: none;
    }

    &-avatar:before {

      width: 2.5em;
      height: 2.5em;
      margin-right: 5px;

      float: left;
      display: inline-block;
      font-size: 1.3em;
      line-height: 2.5em;
      text-align: center;
      border-radius: 50%;
      background: #00a0f2;
      vertical-align: middle;
      color: white;
      content: attr(data-letters);
    }

    &-body {
      padding-left: 60px;
      padding-right: 125px;
      @media only screen and (max-width: 992px){
        padding-right: 0px;
        padding-left: 60px;
      }

      &-title {
        position: relative;
        font-size: $font-size-h4;
        font-family: $font-family-sans-serif;
        padding-bottom: $padding-base-vertical;

        & span.date {
          color: $gray-base;
          font-size: 11px;
          position: absolute;
          right: 0;
          @media only screen and (max-width: 480px){
            width: 100%;
            display: inline-flex;
            position: relative;
          }
        }
      }

      &-message {
        color: $gray;
        margin: 0;
        min-height: 35px;
        word-wrap: break-word;
      }
    }
  }

  &-form {
    margin-left: 60px;
    min-height: 51px;

    &-text {
      float: left;
      padding: 0 0;

      & textarea.form-control {
        width: 100%;
      }
    }
    &-group {
      width: 100%;
    }
    &-button {
      position: inherit;
      right: 25px;
      margin-left: 15px;
    }
  }
}
