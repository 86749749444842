@import '../variables';
// global variables.
@import '../components/mixins'; // global mixings.

/***
New Profile Page
***/

.profile-sidebar {
  float: left;
  width: 300px;
  margin-right: 20px;
}

.profile-content {
  overflow: hidden;
}

/* PROFILE SIDEBAR */

.profile-sidebar-portlet {
  padding: 30px 0 0 0 !important;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 50%;
  height: 50%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-userpic {
  margin-left: 70px;
  display: inline-block;

  & .profile-avatar:before {
    width: 3.5em;
    height: 3.5em;
    margin-right: 5px;

    float: left;
    display: inline-block;
    font-size: 3.3em;
    line-height: 3.5em;
    text-align: center;
    border-radius: 50%;
    background: #00a0f2;
    vertical-align: middle;
    color: white;
    content: attr(data-letters);
  }
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 7px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
}

.profile-userbuttons .btn {
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.profile-userbuttons button {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 6px 15px;
}

.profile-usermenu {
  margin-top: 30px;
  padding-bottom: 20px;
}

.profile-usermenu ul li {
  border-bottom: 1px solid #f0f4f7;
}

.profile-usermenu ul li:last-child {
  border-bottom: none;
}

.profile-usermenu ul li a {
  color: #93a3b5;
  font-size: 16px;
  font-weight: 400;
}

.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 16px;
}

.profile-usermenu ul li a:hover {
  background-color: #fafcfd;
  color: #5b9bd1;
}

.profile-usermenu ul li.active a {
  color: #5b9bd1;
  background-color: #f6f9fb;
  border-left: 2px solid #5b9bd1;
  margin-left: -2px;
}

.profile-stat {
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f4f7;
}

.profile-stat-title {
  color: #7f90a4;
  font-size: 25px;
  text-align: center;
}

.profile-stat-text {
  color: #5b9bd1;
  font-size: 11px;
  font-weight: 800;
  text-align: center;
}

.profile-desc-title {
  color: #7f90a4;
  font-size: 17px;
  font-weight: 600;
}

.profile-desc-text {
  color: #7e8c9e;
  font-size: 14px;
}

.profile-desc-link i {
  width: 22px;
  font-size: 19px;
  color: #abb6c4;
  margin-right: 5px;
}

.profile-desc-link a {
  font-size: 14px;
  font-weight: 600;
  color: #5b9bd1;
}

/* END PROFILE SIDEBAR */

/* RESPONSIVE MODE */
@media (max-width: $screen-sm-max) { /* 991px */
  /* 991px */
  .profile-sidebar {
    float: none;
    width: 100% !important;
    margin: 0;
  }

  .profile-sidebar > .portlet {
    margin-bottom: 20px;
  }

  .profile-content {
    overflow: visible;
  }
}

.margin-bottom {
  margin-bottom: 40px;
}
