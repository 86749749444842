.upload-video {
  &-img{
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
.fa-play-circle.youtube_icon {
  margin: 0 auto;
  line-height: 125px;
  font-size: 100px;
  display: block;
  opacity: 0.7;
}
.modal.fade.show {
  opacity: 1
}
