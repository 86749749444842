.btn {
  &-w80 {
    width: 80px;
    line-height: 1.2;
  }
  &-w110 {
    width: 110px;
    line-height: 1.2;
  }
  .table & {
    line-height: 0.6 !important;
  }
}
