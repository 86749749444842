#visualization {
  //padding-bottom: 40px;
}

.trial-line-navigation {
  position: absolute;
  width: 100%;
  height: 51px;
  overflow: hidden;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);

  &-line {
    position: relative;

    &-cursor {
      position: absolute;
      border: 3px solid $gray-lighter;
      height: 51px;
      width: 100px;
      left: 10px;
      top: -5px;
      background-color: rgba(0, 0, 40, 0.3);
    }

    & .tooltip {
      position: fixed;
      z-index: 999;

      & > .tooltip-inner{
        box-shadow: rgba(0, 0, 0, 0.8) 0 0 15px;
        background-color: rgba(0, 0, 40, 0.8);
        border: solid 1px black;

        text-shadow: 0 0 2px #000;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }

    }


    ul {
      margin-left: 0;
      padding-left: 0;
      font-size: 18px;
      margin-bottom: 5px;

      li {
        position: absolute;
        list-style-type: none;
        width: 25px;
        height: 25px;
        z-index: 3;

        //&:hover{
        //  z-index: 5;
        //}
      }
    }
  }

}