//## Main Layout CSS Builder

@import 'variables'; // theme level variables

@mixin reset-container() {    
  	> .container { 
    	width: 100%;
    	max-width: none !important;  
    	margin: 0 !important;  
	}
} 

@import 'layout/print'; // print layout

@import 'layout/header'; // page header
@import 'layout/content'; // page consnde
@import 'layout/footer';   // page footer
@import 'layout/theme-panel';  // theme customizer panel

@import 'quick-sidebar';  // quick sidebar content

/***  
Page Loading      
***/   

.page-on-load {
	background: #fefefe;

	.page-header,
	.page-container,  
	.page-prefooter,
	.page-footer,
	> .clearfix {
		display: none;
		transition: all 2s;
	}
}