.ticket {
  position: relative;
  height: 190px;
  width: 350px;
  border-radius: 20px !important;
  -webkit-transition: width 1s;
  transition: width 1s;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.25);
  &:hover{
    box-shadow: 0 25px 30px 11px #c7c7c785;
    width: 380px;
    &-header{
      &-label {
        max-width: 185px;
      }
    }
  }
  // @media screen and (max-height: 600px) {
  //   //width: 220px;
  //   width: 270px;
  // }
  .w-100{
    width: 535px;
    padding: 0px 8px;
    background-color: #fff;
    border-radius: 20px;
    border-bottom-left-radius: 0;
  }
  &:hover{
    z-index: 3;
    -webkit-transition: width 1s;
    transition: width 1s;
    &:before {
      border-color: $ticket-select-border-color transparent transparent $ticket-select-border-color;
    }
    &:after {
      border-color: $ticket-select-background transparent transparent $ticket-select-background;
    }
  }

  .vis-item.vis-selected & {
    border-color: $ticket-select-border-color;
    z-index: 2;
    -webkit-transition: width 1s;
    transition: width 1s;
  }

  .vis-item.vis-selected &:before {
    border-color: $ticket-select-border-color transparent transparent $ticket-select-border-color;
  }
  .vis-item.vis-selected &:after {
    border-color: $ticket-select-background transparent transparent $ticket-select-background;
  }

  .vis-item.vis-selected, .vis-item.vis-dot.vis-selected {
    color: $ticket-select-border-color;
    border: 1px solid $ticket-select-border-color;
  }
  &-header{
    display: flex;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #d4d9e9;
    justify-content: space-between;
    &-label {
      font-size: 14px;
      font-weight: 600;
      max-width: 109px;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      text-align: initial;
    }
    &-date {
      color: #6d6d6d;
      font-size: 12px;
      order: 1;
      @media screen and (max-height: 600px) {
        font-size: 10px;
      }
      .fs-lg{
        font-size: 12px;
        font-weight: 700;
        color: #000000;
      }
      .date-calendar{
        display: none !important;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0678f12a;
        border: 1px solid #0678f1;
        margin-right: 10px;
        i{
          font-size: 16px;
          color: #0678f1;
        }
      }
    }
    .icon-gray{
      &:last-child{
        display: none;
      }
    }
    .content-card-heading{
      order: 2;
    }
  }

  &-body {
    margin-top: 7px;
    display: inline-flex;
    flex-wrap: wrap;
    width:100%;
    h4 {
      margin: 0 0 5px 0px;
      font-size: 14px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 10px;
      font-weight: 600;
      margin: 0;
      white-space: normal;
      text-align: left;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      margin-bottom: 25px;
      -webkit-box-orient: vertical;
      @media screen and (max-height: 600px) {
        text-align: left;
      }
    }
    &-img {
      margin-right: 20px;
      width: 100px;
      border-radius: 20px;
      border: 1px solid #dedede;
    }
    &-text {
      width: calc(100% - 120px);
      padding-right: 15px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 12;
      -webkit-box-orient: vertical;
      &:first-child {
          width: 100%;
          margin-right: 0px;
      }
      .control{
        display: flex;
        justify-content: flex-end;
      }
      .btn{
        padding: 4px 5px;
        border-radius: 6px;
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        .text_hover{
          display: block;
          width: 0px;
          overflow: hidden;
          transition: all 1s;
        }
        &:hover{
          width:48px;
         .text_hover{
          width: auto;
          display: block;
          overflow: visible;
          }
          i{
            margin-right: 7px;
          }
        }
        .btn-xs{
          font-size: 10px;

        }

        &.more-btn{
          padding: 4px 8px;
          i{
            margin: 0 !important;
          }
        }
      }
      .text {
        height: 69px;
        overflow: hidden;
        margin-bottom: 10px;
      }
      .btn-wrappper{
        display: flex;
      }
    }
  }
  .ticket-body-control{
    margin: 0;
    padding:5px 5px  0px 0px;
    
  .control{
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-width: 18px;
    .btn{
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 10px;
      display: flex;
      background-color: transparent;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      border: none;
      i{
        color: #fff;
        font-size: 18px;
      }
      &[data-toggle="modal-ajax"]:not(.init){
        display: none;
      }
      &[data-toggle="modal-ajax"].init{
        display: inline-block;
      }
    }
    .badge{
      position: absolute;
      top: 3px;
      right: 2px;
      background-color: #fff;
      color: #000;
      padding: 1px 3px;
    font-size: 10px;
    }
  }
  }
  .ticket-header{
    justify-content: space-between;
  }
}
.img_lazy {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 110px;
}
.img_lazy img{
  height: 110px;
  width: 100%;
  min-height: 110px;
  max-height: 110px;
}
.mr-10{
  margin-right: 40px;
}
.text-item-description{
  p{
    margin: 0
  }
}
.icon-gray{
  color: #8b909f;
  font-size: 14px;
  align-self: center;
  margin: 0 8px  ;
  opacity: 0.6 !important;
}
.time-text{
  font-size: 10px;
  font-weight: 400;
  color: #8b909f;
}
.stroke-transparent {
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
 }