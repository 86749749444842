
// Theme Colors Settigns

// Main settings
$theme-name:                                                    		"default"; 
$brand-main-color:                                              		#044E89;
$brand-main-font-color:				  									#ffffff;
$brand-main-red-color:                                             		#f36a5a;    

// Body and header
$bg-color:                                                         		#fff;
$header-bg-color:                                                  		#fff;      
$header-menu-bg-color:                                             		#044E89;

// Mega Menu
$header-hor-menu-bg-color:                                              #044E89;
$header-hor-menu-fixed-border-box:										0px 1px 10px 0px rgba($header-hor-menu-bg-color, 0.2);

// Default Mega Menu
$header-hor-menu-default-link-font-color:                       		#ffffff;
$header-hor-menu-default-link-font-color-on-hover:              		$header-hor-menu-bg-color;
$header-hor-menu-default-link-font-color-on-active:             		$header-hor-menu-bg-color;

$header-hor-menu-default-link-icon-color:                       		#BCC2CB;
$header-hor-menu-default-link-icon-color-on-hover:              		#ffffff;
$header-hor-menu-default-link-icon-color-on-active:             		lighten($header-hor-menu-bg-color, 4%);

$header-hor-menu-default-link-bg-color-on-hover:                		#ffffff;
$header-hor-menu-default-link-bg-color-on-active:               		#ffffff;

// Default Mega Menu Sub Menu 
$header-hor-menu-dropdown-menu-bg-color:                        		$header-hor-menu-default-link-bg-color-on-hover;
$header-hor-menu-box-shadow:											5px 5px rgba($header-hor-menu-dropdown-menu-bg-color, 0.2);

$header-hor-menu-dropdown-menu-link-bg-color-on-active:           		lighten($header-hor-menu-default-link-bg-color-on-hover, 4%); 
$header-hor-menu-dropdown-menu-link-bg-color-on-hover:           		lighten($header-hor-menu-default-link-bg-color-on-hover, 4%); 

$header-hor-menu-dropdown-menu-link-font-color:                 		#ced5de;
$header-hor-menu-dropdown-menu-link-font-color-on-hover:        		#ced5de;  
$header-hor-menu-dropdown-menu-link-font-color-on-active:        		#ced5de;  

$header-hor-menu-dropdown-menu-link-icon-color:                 		#6fa7d7;
$header-hor-menu-dropdown-menu-link-icon-color-on-hover:        		#6fa7d7;    
$header-hor-menu-dropdown-menu-link-icon-color-on-active:        		#6fa7d7;     

// Default Mega Menu
$header-hor-menu-mega-menu-header-font-color:                   		#ced5de; 

// Light Mega Menu
$header-light-hor-menu-default-link-font-color:                       	$header-hor-menu-default-link-font-color; 
$header-light-hor-menu-default-link-font-color-on-hover:              	$header-hor-menu-default-link-font-color-on-hover;
$header-light-hor-menu-default-link-font-color-on-active:             	$header-hor-menu-default-link-icon-color-on-active;

$header-light-hor-menu-default-link-icon-color:                       	$header-hor-menu-default-link-icon-color;
$header-light-hor-menu-default-link-icon-color-on-hover:              	$header-hor-menu-default-link-icon-color-on-hover;
$header-light-hor-menu-default-link-icon-color-on-active:             	$header-hor-menu-default-link-font-color-on-active;

$header-light-hor-menu-default-link-bg-color-on-hover:                	$header-hor-menu-default-link-bg-color-on-hover;
$header-light-hor-menu-default-link-bg-color-on-active:               	$header-hor-menu-default-link-bg-color-on-active;

$header-light-hor-menu-default-link-bg-color-on-onen:               	darken(#FAFAFC, 0%);
$header-light-hor-menu-default-link-font-color-on-onen:               	#333; 
$header-light-hor-menu-default-link-icon-color-on-onen:               	#333;

// Light Mega Menu Sub Menu 
$header-light-hor-menu-dropdown-menu-bg-color:                        	$header-light-hor-menu-default-link-bg-color-on-onen;
$header-light-hor-menu-box-shadow:										5px 5px rgba($header-hor-menu-dropdown-menu-bg-color, 0.2);

$header-light-hor-menu-dropdown-menu-link-bg-color-on-active:           darken($header-light-hor-menu-dropdown-menu-bg-color, 5%); 
$header-light-hor-menu-dropdown-menu-link-bg-color-on-hover:           	darken($header-light-hor-menu-dropdown-menu-bg-color, 5%); 

$header-light-hor-menu-dropdown-menu-link-font-color:                 	#000;
$header-light-hor-menu-dropdown-menu-link-font-color-on-hover:        	#000;  
$header-light-hor-menu-dropdown-menu-link-font-color-on-active:        	#000;  

$header-light-hor-menu-dropdown-menu-link-icon-color:                 	#6fa7d7;
$header-light-hor-menu-dropdown-menu-link-icon-color-on-hover:        	#6fa7d7;    
$header-light-hor-menu-dropdown-menu-link-icon-color-on-active:        	#6fa7d7;  
 
// Light horizontal mega menu    
$header-light-hor-menu-mega-menu-header-font-color:                   	#555;   

// Mobile Mega Menu
$header-mobile-hor-menu-default-link-font-color:			            #666;

$header-mobile-hor-menu-default-link-bg-color-on-active:                $brand-main-color;
$header-mobile-hor-menu-default-link-font-color-on-active:              #fff;  

$header-mobile-hor-menu-default-link-bg-color-on-hover:                 #fff;
$header-mobile-hor-menu-default-link-font-color-on-hover:               $brand-main-color;

// Header search
$header-search-bg-color:                                        		#38414c;
$header-search-bg-hover-color:                                  		$header-hor-menu-default-link-bg-color-on-hover;
$header-search-bg-color-on-open:                                 		$header-search-bg-hover-color;
$header-search-font-color:                                      		#616d7d; 
$header-search-placeholder-font-color:                          		darken($header-search-font-color, 1%);

// Top menu
$header-top-menu-fixed-border-box:										0px 1px 10px 0px rgba(50, 50, 50, 0.2);
$header-top-menu-separator-color:                                       #E3E8EC;
$header-top-menu-bg-hover-color:                                		#fff;
$header-top-menu-bg-hover-color-on-dropdown-open:               		#fff;       
$header-top-menu-badge-bg-color:                                		$brand-main-color;
$header-top-menu-badge-font-color:                              		#ffffff;
$header-top-menu-icon-font-color:                               		#C1CCD1;

// Top Menu Extended Dropdowns
$header-top-menu-extended-dropdown-item-bg-color-on-hover:              #f8f9fa;
$header-top-menu-extended-dropdown-item-border-color:                   #EFF2F6;
$header-top-menu-extended-dropdown-item-font-color:						#222222;

$header-top-menu-extended-dropdown-header-bg-color:						#f7f8fa;
$header-top-menu-extended-dropdown-header-font-color:					#6f949c;

$header-top-menu-inbox-dropdown-from-font-color:						#5b9bd1;

$header-top-menu-task-dropdown-progress-bg-color:						#dfe2e9;

// Top Menu Notifications
$header-top-menu-notification-time-bg-color:							lighten(#eee, 1%);

$header-top-menu-dropdown-dark-bg-color: 								darken($header-hor-menu-bg-color, 10%);
$header-top-menu-dropdown-dark-header-bg-color:							darken($header-top-menu-dropdown-dark-bg-color, 3%);
$header-top-menu-dropdown-dark-header-font-color:						lighten($header-top-menu-dropdown-dark-header-bg-color, 50%);

$header-top-menu-dropdown-dark-item-font-color:							#aaafb7;  
$header-top-menu-dropdown-dark-item-border-color:						lighten($header-top-menu-dropdown-dark-bg-color, 6%);
$header-top-menu-dropdown-dark-item-icon-color:							#6FA7D7;
$header-top-menu-dropdown-dark-item-bg-color-on-hover:					lighten($header-top-menu-dropdown-dark-bg-color, 4%);

$header-top-menu-dropdown-dark-default-menu-divider:					$header-top-menu-dropdown-dark-item-border-color;

$header-top-menu-dropdown-dark-notification-time-bg-color:				darken($header-top-menu-dropdown-dark-bg-color, 5%);

// Top Menu User bar
$header-top-menu-user-font-color:                                       lighten(#7f96ac, 5%); 
$header-top-menu-user-bg-color:                                 		$header-search-bg-color;
$header-top-menu-user-font-color-on-hover:								darken($header-top-menu-user-font-color, 10%); 

// Footer
$footer-pre-bg-color:                                     				lighten($header-hor-menu-bg-color, 2%);   
$footer-pre-font-color:													lighten($footer-pre-bg-color, 35%);  
$footer-default-font-color:												lighten($header-hor-menu-bg-color, 37%);
$footer-bg-color:                                     					darken($header-hor-menu-bg-color, 4%);
$footer-scroll-top-icon-color:                                          lighten($header-hor-menu-bg-color, 15%);

@import 'base';