.trial-line {
    margin-bottom: 15px;
    & .left-actions {
        padding-top: 20px;
        & .btn {
            line-height: 1 !important;
        }
    }
    & #background {
        .thumbnail > img {
            height: 100%;
        }
    }
}

.btn-blue {
    background-color: $header-hor-menu-bg-color;
    color: #ffffff;
}

.headerlogin {
    &-user-avatar {
        float: left;
        margin-left: 10px;
        &:before {
            width: 2.5em;
            height: 2.5em;
            margin-top: -6px;
            display: inline-block;
            font-size: 17px;
            font-weight: 800;
            line-height: 2.45em;
            text-align: center;
            border-radius: 50%;
            border: 1px solid #50A1D1;
            vertical-align: middle;
            color: #50A1D1;
            content: attr(data-letters);
        }
    }
    &-user-name {
        color: $header-hor-menu-bg-color;
        font-weight: 400;
        float: left;
        margin: 5px 10px;
    }
    &-logout {
        color: #50A1D1;
        float: left;
        font-size: 20px;
    }
    &-message {
        margin-right: 20px;
        float: left;
        color: $header-hor-menu-bg-color;
        & button {
            margin-left: 20px;
        }
        & span {
            color: #B53F3F;
        }
    }
}

.margin-reset {
    margin-left: 0;
    margin-right: 0;
}

#background-preview {
    z-index: 20;
    & .fa-upload {
        opacity: 0.2;
        display: block;
        font-size: 100px;
        z-index: 0;
        &:before {
            text-align: center;
            display: block;
            line-height: 150px;
        }
    }
}

#category-background-preview {
    z-index: 20;
    & .fa-upload {
        opacity: 0.2;
        display: block;
        font-size: 100px;
        z-index: 0;
        &:before {
            text-align: center;
            display: block;
            line-height: 100%;
        }
    }
}

.note-small {
    font-size: 15px;
}

.actions-filter a.logo {
    height: 50px;
    margin: 0;
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    width: 182px;
    &:hover {
        background-color: transparent;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
