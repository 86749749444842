.my-dashboard-list {
  &-body {

  }
  &-item.__header {
    border-bottom: 1px solid #b8b8b8;
    font-size: 10px;
  }
  &-item {
    padding: 10px 0;
    border-bottom: 1px solid #b8b8b8;


    .btn {
      line-height: 1.1;
      width: 70px;
    }
    .__title,
    .__status,
    .__date {
      margin-bottom: 10px;
    }
    .__title{
      color: $header-hor-menu-bg-color;
    }
  }

  &-show-more {
    margin: 20px 0;
  }
}
