
@font-face {
  font-family: Roboto;
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url('../../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url('../../assets/fonts/Montserrat/Montserrat-Bold.ttf');
  font-display: swap;
}
