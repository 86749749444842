// Global settings
body {
  overscroll-behavior-x: none !important;
  overscroll-behavior-y: none !important;
}

$grid-float-breakpoint: 1025px;
$bootstrap-sass-asset-helper: false !default;
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../../fonts/bootstrap/") !default;
@import 'variables';
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap.scss';
@import 'components/mixins';

@import 'components/reset-general';

//Components
//
@import 'components/colors';
@import 'components/typography';
@import 'components/font-icons';
@import 'components/buttons';
@import 'components/feeds';
@import 'components/icon-buttons';
@import 'components/labels';
@import 'components/metro-icons';
@import 'components/font-awesome';
@import 'components/modals';
@import 'components/tiles';
@import "components/bootstrap-timepicker";
@import "components/colorpicker";
@import "components/vis";

@import 'components/utils';
@import 'components/dropdowns';
@import 'components/form-layouts';
@import 'components/inputs';
@import 'components/input-icons';
@import 'components/social-icons';
@import 'components/tables';
@import 'components/tabs';
@import 'components/portlets';

@import "layout/layout";
@import "layout/themes/default";
@import "layout/footer";

//Modules
@import "modules/buttons";
@import "modules/dashboard-list";
@import "modules/dropzone";
@import "modules/widget-tools";
@import "modules/form-validation";
@import "modules/ticket";
@import "modules/editable";
@import "modules/table-sort";
@import "modules/comments";
@import "modules/collaps-panel";
@import "modules/modals";
@import "modules/trial-navigation";
@import "modules/upload-video";

//Pages
@import "pages/media-library";
@import "pages/trailline-general";
@import "pages/list-events";
@import "pages/login";
@import "pages/time-line";
@import "pages/profile";
@import "pages/support";
@import "tracks";

@import "./fonts";
@import "~@ng-select/ng-select/themes/default.theme.css";
